<template>
  <transition name="alert-fade">
    <div @click="close()" class="alert" :class="alerts.type" v-if="alerts.msg" v-show="alertVisible"
         v-html="alerts.msg">
    </div>
  </transition>
</template>


<script>
export default {
  name: "Alerts",
  props: ["alerts"],
  computed: {
    alertVisible: function() {
      if (this.alerts.msg != null) {
        setTimeout(() => this.alerts.msg = null, 7000);
        return true;
      }
      return false;
    },
  },
  methods: {
    close() {
      this.alerts.msg = null;
    },
  },
};
</script>


<style>
.alert-deck {
  position: fixed;
  width: 100%;
  max-width: 350px;
  z-index: 3900;
  top: 0;
  right: 0;
}

.alert {
  margin: 15px;
  padding: .9rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0;
  border-radius: .23rem;
  opacity: 1;
  overflow: hidden;
  min-height: 70px;
  cursor: pointer;
  color: #ffffff !important;
  animation: createAlert .25s ease-in-out;
}

.alert.success {
  background-color: green;
}

.alert.error {
  background-color: red;
}

.alert-fade-enter-active,
.alert-fade-leave-active {
  animation: createAlert .25s ease-in-out;
}

.alert-fade-enter-from,
.alert-fade-leave-to {
  animation: removeAlert .25s ease-in-out;
}

@keyframes createAlert {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes removeAlert {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-50px);
  }
}
</style>