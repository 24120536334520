<template>
  <Header v-if="initData.initLoader===false" :initData = "initData" :contents = "activeContent"></Header>
  <Loader :initData="initData"/>
  <InitLoader :initData="initData"/>
  <div class="alert-deck">
    <Alerts :alerts="alert" v-for="alert in initData.alerts"/>
  </div>
  <router-view v-if="initData.initLoader===false" :initData = "initData" :contents = "activeContent"/>
  <Footer v-if="initData.initLoader===false" :initData = "initData" :contents = "activeContent"></Footer>
</template>

<script>
import {reactive} from 'vue'
import Loader from "./components/Loader"
import InitLoader from "./components/InitLoader"
import Alerts from "./components/Alerts"
import Header from "./components/Header"
import Footer from "./components/Footer"
import {Response} from '@/classes/response.js'

export default {
  name: 'App',
  components: {
    InitLoader, Loader, Alerts, Header, Footer
  },
  data(){
    // DEFAULT INIT DATA
    const initData = reactive({
      'user' : {
        'status' : null,
        'teamName' : null,
        'questionData' : null,
        'counter' : null,
        'startDateTime' : null
      },
      'initLoader' : true,
      'loader' : false,
      'alerts' : [],
      'language' : null,
      'contents' : {'hu':{}},
      'bigImage' : null
    })

    return {
      initData
    }
  },
  created(){
    this.userInit()
  },
  computed:{
    activeContent(){
      let userLanguage = this.initData.language
      return this.initData.contents[userLanguage]
    },
    bigImage(){
      let bigImage = this.initData.bigImage
      let body = document.querySelector('body')
      if(bigImage!==null && !body.classList.contains('bg-'+bigImage)){
        body.classList.remove('bg-1','bg-2')
        body.classList.add('bg-'+bigImage)
      }else{
        body.classList.remove('bg-1','bg-2')
      }
      return bigImage
    }
  },
  watch:{
    bigImage(){
      console.log('bigImage changed')
      console.log(this.initData.bigImage)
    }
  },
  methods:{
    userInit(){
      this.$axios.get('/api/v1/user/')
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.status===true) {
              console.log(responseData)
              this.initData.language = responseData.data.language
              this.initData.contents = responseData.data.contents
              this.initData.user = responseData.data.user
            }
          })
          .catch(error => {
            console.log(error)
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            this.initData.initLoader = false
          });

    }
  }
}
</script>
<style>
.bg-1{
  background-image: url('@/assets/images/background_1.jpg');
  background-size: cover;
}
.bg-2{
  background-image: url('@/assets/images/background_2.jpg');
  background-size: cover;
}
</style>
