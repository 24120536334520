<template>
  <header class="header fixed-top">
    <div class="header-line"></div>
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container-fluid">
        <a class="navbar-brand" href="">
          E.ON Challenge.ON Selejtező
        </a>
        <a class="navbar-brand ms-auto" href="">
          <img src="@/assets/images/logo.svg" alt="EON logó" class="logo">
        </a>
      </div>
    </nav>
  </header>
</template>

<script>
import {Response} from "@/classes/response";

export default {
  name: 'Header',
  props: ['initData','contents'],
  data() {
    return {

    }
  },
  methods:{
    changeLanguage(language){
      this.initData.loader = true

      let formData = new FormData()
      formData.append('language', language)

      this.$axios.post('/api/v1/user/language',formData)
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.status===true) {
              this.initData.language = language
            }
          })
          .catch(error => {
            console.log(error)
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            this.initData.loader = false
          });
    }
  }
}
</script>
<style scoped>
header{
  background-color: #fff;
}
.header-line{
  height: 4px;
  background-image: linear-gradient(to right, #5CC1CB 0% 60%, #E3E000 60% 70%, #EA1B0A 70% 100%);
}
.navbar{
  border-bottom: 4px solid #f6f6f7;
}
</style>