<template>
  <footer>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-sm-12 col-md-4">
          <span class="text-danger">{{ teamName }}</span>
          <span class="ps-3" @click="this.Game.methods.logOut(this.$parent)" style="cursor:pointer">kijelentkezés</span>
          <span v-if="isTestTeam==1" class="ps-5 text-danger" @click="this.Game.methods.restart(this.$parent)">reset</span>
        </div>
        <div class="col-sm-12 col-md-4">
          ©{{new Date().getFullYear()}} E.ON Hungária Zrt.
        </div>
        <div class="col-sm-12 col-md-4 text-end">
          <img src="@/assets/images/logo.svg" alt="EON logó" class="logo">
        </div>
      </div>
    </div>
    <div class="line"></div>
  </footer>
</template>

<script>
import Game from "@/views/Game"

export default {
  name: 'Footer',
  props: ['initData','contents'],
  components:{
    Game
  },
  data(){
    return{
      Game
    }
  },
  computed:{
    teamName(){
      return this.initData.user.teamName
    },
    isTestTeam(){
      return this.initData.user.isTestTeam
    }
  }
}
</script>
<style scoped>
footer{
  min-height: 4rem;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  color: #8f9092;
  font-size: 0.8rem;
  line-height: 0.8rem;
}
.container{
  border-top: 4px solid #e8e7e9;
  padding-top: 1rem;
}
.line{
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 4px;
  background-image: linear-gradient(to right, #5CC1CB 0% 60%, #E3E000 60% 70%, #EA1B0A 70% 100%);
}
</style>